import { onlyNumbers } from '../utility';
import Inputmask from 'inputmask';

export class DonationForm {
    private $donationForm: JQuery<HTMLElement>;
    private amountOther$ = $(".donation-amounts-other input");
    private donationAmounts$ = $(".donation-amounts");

    // These rules are for validating the create or existing account fields for recurring donations.
    // Due to the complexity of adding conditional requirements to these fields on the server side (e.g. IndexViewModel)
    // we will just implement them on the client side, and the form should not be able to submit until theses
    // fields are valid
    private billingFullNameInfoRules: JQueryValidation.RulesDictionary = {
        required: true,
        maxlength: 255,
        messages: {
            required: 'Full Name is required',
        },
    };

    private emailRules: JQueryValidation.RulesDictionary = {
        required: true,
        email: true,
        messages: {
            required: 'Please enter your email address',
            email: 'Please enter a valid email address',
        },
    };

    private loginPasswordRules: JQueryValidation.RulesDictionary = {
        required: true,
        messages: {
            required: 'Password is required',
        },
    };

    private registrationNameRules: JQueryValidation.RulesDictionary = {
        required: true,
        maxlength: 255,
        messages: {
            required: 'Please enter your full name',
            maxlength: 'Full Name cannot exceed 255 characters',
        },
    };

    private registrationPasswordRules: JQueryValidation.RulesDictionary = {
        required: true,
        minlength: 8,
        complexity: true,
        messages: {
            required: 'Password is required',
        },
    };

    private firstNameRules: JQueryValidation.RulesDictionary = {
        required: true,
        maxlength: 50,
        messages: {
            required: 'Please enter your first name',
            maxlength: 'First Name cannot exceed 50 characters',
        },
    };

    private lastNameRules: JQueryValidation.RulesDictionary = {
        required: true,
        maxlength: 50,
        messages: {
            required: 'Please enter your last name',
            maxlength: 'Last Name cannot exceed 50 characters',
        },
    };

    private addressRules: JQueryValidation.RulesDictionary = {
        required: true,
        maxlength: 255,
        messages: {
            required: 'Please enter your address',
            maxlength: 'Address cannot exceed 255 characters',
        },
    };

    private cityRules: JQueryValidation.RulesDictionary = {
        required: true,
        maxlength: 50,
        messages: {
            required: 'Please enter your city',
            maxlength: 'City cannot exceed 50 characters',
        },
    };

    private zipRules: JQueryValidation.RulesDictionary = {
        required: true,
        minlength: 5,
        maxlength: 5,
        digits: true,
        messages: {
            required: 'Please enter your ZIP code',
            digits: 'Please enter a valid ZIP code',
            minlength: 'ZIP code should contain 5 digits',
            maxlength: 'ZIP code should contain 5 digits',
        },
    };

    constructor() {
        this.$donationForm = $('#donationform');

        const donationFrequency = $('.donation-frequency input[type=radio]:checked').val();

        // Initialize form validation
        this.$donationForm.validate();

        this.addPasswordComplexityRule();
        this.adjustFormInputs((donationFrequency || '').toString());
        this.attachEventListeners();
        this.genericEvents();

        this.addBaseValidationRules();
        this.attachValidationOnBlur();
        this.initializeInputMasking();
    }

    /**
     * Initialize input masking for ZIP code and phone number fields
     */
    private initializeInputMasking() {
        // Input mask for ZIP code: accepts both 5 digits and ZIP+4 format
        const zipInput = document.querySelector("#Zipcode") as HTMLInputElement;
        if (zipInput) {
            Inputmask({
                mask: "99999",  // Allows 5 digits
                placeholder: "",       // No placeholder visible
                showMaskOnHover: false  // Mask is only visible when typing
            }).mask(zipInput);
        }

        // Input mask for phone number: (XXX) XXX-XXXX format
        const phoneInput = document.querySelector("#Phone") as HTMLInputElement;
        if (phoneInput) {
            Inputmask({
                mask: "(999) 999-9999",
                placeholder: "",       // No placeholder visible
                showMaskOnHover: false  // Mask is only visible when typing
            }).mask(phoneInput);
        }
    }

    /**
     * Handle all the donation amount logic
     */
    public handleOtherAmount = () => {
        //  Unselect amount and select text amount
        const checkedAmount$ = $(".donation-amounts input[type=radio]:checked");
        const amount = this.amountOther$.val();

        if (amount && +amount > 0) {
            // Add the selected class to the whole section
            $('.donation-amounts-other').addClass('donation-amounts-other--selected');

            // Remove checked from all the other donation buttons
            if (checkedAmount$.length > 0) {
                checkedAmount$.prop('checked', false);
            }

            return;
        }

        // If there is no amount and nothing is checked, select the $100 amount
        if ((!amount || +amount > 0) && checkedAmount$.length === 0) {
            const $amount100 = $("#amount-100");
            $amount100.prop('checked', true);
            $amount100.trigger('change');

            // Remove the selected class from the whole section
            $('.donation-amounts-other').removeClass('donation-amounts-other--selected');

            return;
        }
    };

    /**
     * Remove selected from other and reset the value to an empty string
     */
    private handleDonationAmounts = (e: Event) => {
        const button = e.target as HTMLInputElement;

        if (button.type === 'radio' && this.amountOther$.length > 0) {
            $('.donation-amounts-other').removeClass('donation-amounts-other--selected');
            this.amountOther$.val('')

            button.checked = true;
        }
    };

    /**
     * Just some general, generic events
     */
    private genericEvents() {
        const donationFrequency$ = $('.donation-frequency');
        const account$ = $<HTMLInputElement>("[name='Account']");
        const isUserLoggedIn$ = $<HTMLInputElement>("[name='IsUserLoggedIn']");
        const selectedOtherAmount = $<HTMLInputElement>("[name='SelectedOtherAmount']");

        if (account$.length > 0) {
            this.handleAccountFields(account$);
        }

        if (donationFrequency$.length > 0) {
            donationFrequency$.on('change', (e) => {
                this.handleRecurringFields(e);
                this.getAccountValue(account$, isUserLoggedIn$);
            });
        }

        this.addCommasSeparator(selectedOtherAmount);
    }

    private addCommasSeparator(otherAmount$: JQuery<HTMLInputElement>) {
        otherAmount$.each((_, element) => {
            element.addEventListener('blur', (e: Event) => {
                const target: HTMLInputElement = e.target as HTMLInputElement;

                let value = target.value;

                value = value.replace(/[^0-9.]/g, '');

                const parts = value.split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                if (parts[1]) {
                    parts[1] = parts[1].substring(0, 2); // Limit to two decimal places
                }
                
                target.value = parts.join('.');

                // Ensure two decimal places
                if (!target.value.includes('.')) {
                    target.value += target.value ? '.00' : '';
                } else if (target.value.split('.')[1].length === 1) {
                    target.value += '0';
                } else if (target.value.split('.')[1].length === 0) {
                    target.value += '00';
                }
            });
        });
    }

    private handleAccountFields(account$: JQuery<HTMLInputElement>) {
        account$.each((_, element) => {
            element.addEventListener('change', (e: Event) => {
                const target: HTMLInputElement = e.target as HTMLInputElement;

                if (target.checked) {
                    if (target.value === 'create') {
                        this.addRegistrationValidationRules();
                    } else if (target.value === 'existing') {
                        this.addLoginValidationRules();
                    }
                }
            });
        })
    }

    private getAccountValue(account: JQuery<HTMLInputElement>, isUserLoggedIn: JQuery<HTMLInputElement>) {
        if (!isUserLoggedIn.val()) {
            const create: HTMLInputElement = account[0];
            const existing: HTMLInputElement = account[1];
            if (create.checked) {
                this.addRegistrationValidationRules();
            } else if (existing.checked) {
                this.addLoginValidationRules();
            }
        }
    }

    /**
     * Hide and show the appropriate section for recurring donations
     */
    private handleRecurringFields(event: JQuery.ChangeEvent) {
        const freqButton = event.target as HTMLInputElement;

        if (freqButton?.type !== 'radio') {
            return;
        }

        if (freqButton.value === 'recurring' && freqButton.checked) {
            this.adjustFormInputs(freqButton.value);
            this.addRegistrationValidationRules();
        }

        if (freqButton.value === 'one-time' && freqButton.checked) {
            this.adjustFormInputs(freqButton.value);
            this.addBillingInfoValidationRules();
        }
    }

    private adjustFormInputs(frequency: string) {
        const accountForm$ = $('.inline-fend');
        const nameField$ = $('.donate-full-name');
        const emailField$ = $('.donate-email');

        if (frequency === 'recurring') {
            accountForm$.removeClass('hidden');
            nameField$.addClass('hidden');
            emailField$.addClass('hidden');
            nameField$.children('input').prop('required', false);
            emailField$.children('input').prop('required', false);
        } else {
            accountForm$.addClass('hidden');
            nameField$.removeClass('hidden');
            emailField$.removeClass('hidden');
            nameField$.children('input').prop('required', true);
            emailField$.children('input').prop('required', true);
        }
    }

    private attachEventListeners() {
        this.amountOther$.on('blur', this.handleOtherAmount);

        this.amountOther$.on('keypress', onlyNumbers);

        this.donationAmounts$.on('change', this.handleDonationAmounts)
    }

    private addBaseValidationRules() {
        // Add validation rules to the form fields
        $('#FirstName').rules('add', this.firstNameRules);
        $('#LastName').rules('add', this.lastNameRules);
        $('#Email').rules('add', this.emailRules);
        $('#Address').rules('add', this.addressRules);
        $('#City').rules('add', this.cityRules);
        $('#Zipcode').rules('add', this.zipRules);
    }

    private attachValidationOnBlur() {
        const fieldsToValidateOnBlur = ['#FirstName', '#LastName', '#Address', '#City', '#Zipcode', '#Email', '#RegistrationName', '#RegistrationEmail', '#RegistrationPassword', '#LoginEmail', '#LoginPassword'];

        fieldsToValidateOnBlur.forEach((selector) => {
            $(selector).on('blur', function () {
                $(this).valid(); // Trigger validation using existing rules
            });
        });
    }

    private addLoginValidationRules() {
        $('#FullName').rules('remove');
        $('#Email').rules('remove');
        $('#RegistrationName').rules('remove');
        $('#RegistrationEmail').rules('remove');
        $('#RegistrationPassword').rules('remove');
        $('#LoginEmail').rules('add', this.emailRules);
        $('#LoginPassword').rules('add', this.loginPasswordRules);
    }

    private addRegistrationValidationRules() {
        $('#FullName').rules('remove');
        $('#Email').rules('remove');
        $('#LoginEmail').rules('remove');
        $('#LoginPassword').rules('remove');
        $('#RegistrationName').rules('add', this.registrationNameRules);
        $('#RegistrationEmail').rules('add', this.emailRules);
        $('#RegistrationPassword').rules('add', this.registrationPasswordRules);
    }

    private addBillingInfoValidationRules() {
        $('#FullName').rules('add', this.billingFullNameInfoRules);
        $('#LoginEmail').rules('remove');
        $('#LoginPassword').rules('remove');
        $('#RegistrationName').rules('remove');
        $('#RegistrationEmail').rules('remove');
        $('#RegistrationPassword').rules('remove');
    }

    private addPasswordComplexityRule() {
        $.validator.addMethod(
            'complexity',
            (p) => {
                let regex = /(\w)\1{2,}/g;
                return !regex.test(p);
            },
            'Password must not contain 3 or more repeated characters'
        );
    }
}
